import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import parse from "class-parser";
import * as Database from "services/firebase/database";
import "./style.scss";
import Loading from "components/Loading";

const Image = ({ data }) => {
	const className = parse("image", { landscape: data.landscape });
	const style = { backgroundImage: `url(${data.url})` };

	return <div className={className} style={style} />;
};

const Gallery = () => {
	const [gallery, setGallery] = useState({});
	const location = useLocation().pathname.split('/')[1];

	//#region EFFECTS
	useEffect(() => {
		Database.getGalleryFromURL(location).then(data => { setGallery(data); });
	}, []);
	//#endregion

	return Object.keys(gallery).length === 0 ? <Loading /> : (
		<div className="gallery align-horizontal-center">
			<div className="gallery-title align-horizontal-center">
				<h2>{gallery.title}</h2>
			</div>
			<div className="images">
				{gallery.order.map(id => {
					const image = gallery.images.find(img => img.id === id);
					return <Image key={image.id} data={image} />;
				})}
			</div>
		</div>
	);
};

export default Gallery;
