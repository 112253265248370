import * as Storage from "firebase/storage";
import { storage } from "services/firebase";

const GALLERIES = "newGalleries";

//#region METHODS
export const deleteImagesGallery = (id, images) => {
	return Promise.all(images.map(image => deleteImage(id, image.name)));
};
export const deleteImage = (id, imageName) => {
	return Promise.all(
		//["_fullSize", "_mobile", "_thumb"].map(name => {
		["_fullSize", "_thumb"].map(name => {
			const imageRef = Storage.ref(storage, `${GALLERIES}/${id}/${imageName + name}.jpeg`);
			Storage.deleteObject(imageRef);
		})
	);
};
export const uploadImage = async (image, id, imageName = "") => {
	const imageRef = Storage.ref(storage, `${GALLERIES}/${id}/${imageName}.jpeg`);

	const uploadTask = Storage.uploadString(imageRef, image, "data_url");
	return uploadTask.then(snap => Storage.getDownloadURL(snap.ref));
};
//#endregion
