import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";

import parse from "class-parser";

import "./style.scss";

const Header = ({ navOpen, handleSetNav }) => {
	return (
		<div className="header">
			<div className="header-content">
				<div className="title">
					<h1>R I M A E T E R N A X</h1>
					<h2>
						<span>G A M E</span>
						<span>C A P T U R E</span>
						<span>A R T I S T</span>
					</h2>
				</div>
				<nav className={parse({ open: navOpen })}>
					<div className="burger-menu" onClick={() => handleSetNav()}>
						<FontAwesomeIcon icon={navOpen ? faXmark : faBars} />
					</div>

					<Link to="/" onClick={() => handleSetNav(false)}>PORTFOLIO</Link>
					<Link to="/about-me" onClick={() => handleSetNav(false)}>ABOUT ME</Link>
					<Link to="/contact-me" onClick={() => handleSetNav(false)}>CONTACT ME</Link>
				</nav>
			</div>
		</div>
	);
};

export default Header;
