import { useEffect, useState } from "react";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import { faInstagram, faTwitter } from "@fortawesome/free-brands-svg-icons";

import { sendEmail } from "services/sendEmail";

import "./style.scss";

import { TextInput } from "components/Input";
import Button from "components/Button";
import Loading from "components/Loading";

const Contact = () => {
	const [data, setData] = useState({
		name: "",
		email: "",
		subject: "",
		message: ""
	});
	const [validEmail, setValidEmail] = useState(null);
	const { send, success, sending } = sendEmail();

	//#region HANDLERS
	const handleCheckContent = () => {
		return (
			data.name !== "" &&
			data.email !== "" &&
			data.subject !== "" &&
			data.message !== "" &&
			!validEmail
		);
	};

	const handleSetValue = (value, target) => {
		setData(prev => {
			const newData = { ...prev };
			newData[target] = value;
			return newData;
		});
	};

	const handleSendEmail = () => { send(data) };
	//#endregion

	//#region EFFECTS
	useEffect(() => {
		if (data.email) setValidEmail((
			data.email !== "" &&
			!(data.email.includes("@") && (
				data.email.includes(".com")) ||
				data.email.includes(".es")
			)
		))
	}, [data]);

	useEffect(() => {
		if (success) {
			setData({
				name: "",
				email: "",
				subject: "",
				message: ""
			});
		}
	}, [sending]);
	//#endregion

	return (
		<div className="contact-me">
			<div className="content">
				<h2>Contact Me</h2>
				<div className="row">
					<TextInput
						label="Your Name"
						placeHolder="Jane Doe"
						value={data.name}
						setValue={value => handleSetValue(value, "name")} />

					<TextInput
						label="Your Email"
						placeHolder="jdoe@example.com"
						value={data.email}
						setValue={value => handleSetValue(value, "email")}
						error={validEmail} />
				</div>
				<div className="row">
					<TextInput
						label="Subject"
						placeHolder="Let's work together!"
						value={data.subject}
						setValue={value => handleSetValue(value, "subject")} />
				</div>
				<div className="row">
					<TextInput
						textArea
						label="Message"
						placeHolder="Your message"
						value={data.message}
						setValue={value => handleSetValue(value, "message")} />
				</div>
				<div className="row align-horizontal-center">
					{sending ?
						<Loading type="email" /> :
						<Button
							label="Send Message"
							active={handleCheckContent()}
							onClick={handleSendEmail} />
					}
				</div>
			</div>
		</div>
	);
};

/*const Contact = () => {
	return (
		<div className="contact-me">
			<div className="column align-horizontal-center">
				<p className="title">
					Direct messages are not available yet<br />but you can talk to me on my social media.
				</p>
			</div>
			<div className="box">
				<div className="content align-horizontal-center">
					<h1>My Social Media</h1>
					<div className="row">
						<a href="https://twitter.com/Rimaeternax" target="_blank" rel="noopener noreferrer"><div className="icon twitter"><FontAwesomeIcon icon={faTwitter} />Twitter</div></a>
						<a href="https://www.instagram.com/rimaeternax" target="_blank" rel="noopener noreferrer"><div className="icon instagram"><FontAwesomeIcon icon={faInstagram} />Instagram</div></a>
					</div>
				</div>
			</div>
		</div>
	);
};*/

export default Contact;