import "./style.scss";

const Footer = () => {
	const year = new Date().getFullYear();

	return (
		<div className="footer">
			<small>Copyright © 2021-{year} Rimaeternax. All Rights Reserved</small>
		</div>
	);
};

export default Footer;