import * as Auth from "firebase/auth";
import { auth } from "services/firebase";

//#region METHODS
export const checkAuthState = setUser => {
	Auth.onAuthStateChanged(auth, user => {
		const uid = user?.uid;
		setUser(uid);
	});
};

export const getCurrentUser = () => {
	return auth.currentUser;
};

export const loginUsernameAndPassw = (username, passw, callback, errorCallback) => {
	Auth.signInWithEmailAndPassword(auth, username, passw)
		.then(callback).catch(errorCallback);
};

export const logout = (callback, errorCallback) => {
	Auth.signOut(auth).then(callback).catch(errorCallback);
};
//#endregion
