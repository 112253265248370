import { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { logout } from "services/firebase/auth";

import { AuthContext } from "providers/auth";
import { ToastContext } from "providers/toast";

import Galleries from "./sections/GalleriesEditor";
import Login from "./sections/Login";

import Button from "components/Button";

import "./style.scss";

const Editor = () => {
	const { login } = useContext(AuthContext);
	const { setToast } = useContext(ToastContext);

	const [loading, setLoading] = useState(true);

	useEffect(() => {
		setTimeout(() => {
			setLoading(false);
		}, 1000);
	}, []);

	//#region HANDLERS
	const handleLogOut = () => {
		logout(() => {
			setToast({
				title: "Bye!",
				message: "You logged out successfully!",
				type: "success"
			});
		}, () => {
			setToast({
				title: "Wops!",
				message: "Something is wrong...",
				type: "error"
			});
		});
	};
	//#endregion

	return (
		<div className="editor">
			<div className="editor-header row">
				<Link to="/"><span className="title">Rimaeternax</span></Link>
				<p>EDITOR</p>
				<Button
					label={login ? "Log out" : ""}
					active={login !== null}
					onClick={handleLogOut} />
			</div>
			<div className="content expand">
				{loading ? null : (
					login !== null && login ? <Galleries /> : <Login />
				)}
			</div>
		</div>
	);
};

export default Editor;
