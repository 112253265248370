import "./style.scss";

import Cover from "components/Cover";

const Covers = ({ data, message }) => {
	return (
		<div className="covers">
			{message}
			{data.map(({ id, label, cover, url }) =>
				<Cover
					key={id}
					label={label}
					cover={cover}
					url={url} />
			)}
			<div className="filler"></div>
			<div className="filler"></div>
			<div className="filler"></div>
		</div>
	);
};

export default Covers;
