//import { useEffect, useState } from "react";
//import * as Database from "services/firebase/database";
import "./style.scss";

const About = () => {
	return (
		<div className="about-me row">
			<div className="side-column">
				<div className="profile-picture align-center">
					<div className="shutter">
						<div className="picture"></div>
						<div className="thingies-content">
							<div className="thingy thing-1"></div>
							<div className="thingy thing-2"></div>
							<div className="thingy thing-3"></div>
							<div className="thingy thing-4"></div>
							<div className="thingy thing-5"></div>
							<div className="thingy thing-6"></div>
						</div>
					</div>
				</div>
				<div className="social-media column">
					<a href="https://twitter.com/Rimaeternax" target="_blank" rel="noopener noreferrer">
						<div className="icon twitter">
							<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="twitter" className="svg-inline--fa fa-twitter" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
								<path
									fill="currentColor"
									d="M459.4 151.7c.325 4.548 .325 9.097 .325 13.65 0 138.7-105.6 298.6-298.6 298.6-59.45 0-114.7-17.22-161.1-47.11 8.447 .974 16.57 1.299 25.34 1.299 49.06 0 94.21-16.57 130.3-44.83-46.13-.975-84.79-31.19-98.11-72.77 6.498 .974 12.99 1.624 19.82 1.624 9.421 0 18.84-1.3 27.61-3.573-48.08-9.747-84.14-51.98-84.14-102.1v-1.299c13.97 7.797 30.21 12.67 47.43 13.32-28.26-18.84-46.78-51.01-46.78-87.39 0-19.49 5.197-37.36 14.29-52.95 51.65 63.67 129.3 105.3 216.4 109.8-1.624-7.797-2.599-15.92-2.599-24.04 0-57.83 46.78-104.9 104.9-104.9 30.21 0 57.5 12.67 76.67 33.14 23.72-4.548 46.46-13.32 66.6-25.34-7.798 24.37-24.37 44.83-46.13 57.83 21.12-2.273 41.58-8.122 60.43-16.24-14.29 20.79-32.16 39.31-52.63 54.25z"
								></path>
							</svg>
							Twitter
						</div>
					</a>
					<a href="https://www.instagram.com/rimaeternax" target="_blank" rel="noopener noreferrer">
						<div className="icon instagram">
							<svg aria-hidden="true" focusable="false" data-prefix="fab" data-icon="instagram" className="svg-inline--fa fa-instagram" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
								<path
									fill="currentColor"
									d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
								></path>
							</svg>
							Instagram
						</div>
					</a>
				</div>
			</div>
			<div className="content">
				<h2>Hi! I'm Luna</h2>
				<h3>Something about me</h3>
				<p>
					I live in Spain, one of the things I do is to capture the scenes and art of videogames through images.
					I adapt to any kind of photography whether it's portrait, landscape, action.
					<br />
					<br />
					Here you can see all the games I'm capturing both in console and PC.
				</p>
				<h3>Collaborations</h3>
				<p>
					I make screenshots and gameplay/cinematics capture for video games marketing teams publishers/studios.
					<br />
					<br />
					Some of the clients I have worked with:
					<br />
					<strong>NVIDIA</strong>, <strong>PlayStation Talents</strong> and <strong>Selecta Play</strong>
				</p>
			</div>
		</div>
	);
};

export default About;
