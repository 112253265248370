import { createContext, useEffect, useState } from "react";
import * as Auth from "services/firebase/auth";

const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
	const [login, setLogin] = useState(false);

	useEffect(() => {
		Auth.checkAuthState(setLogin);
	}, []);

	return (
		<AuthContext.Provider value={{ login, setLogin }}>
			{children}
		</AuthContext.Provider>
	);
};

export default AuthProvider;
export { AuthContext };
