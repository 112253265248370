import { createContext, useEffect, useState } from "react";
import Toast from "components/Toast";

const HIDE_TOAST_IN_SECONDS = 10;

const ToastContext = createContext(null);

const ToastProvider = ({ children }) => {
	const [toast, setToast] = useState(() => ({
		label: "",
		message: "",
		type: "",
	}));
	const [visible, setVisible] = useState(false);

	useEffect(() => {
		if (toast.label || toast.message) {
			setVisible(true);

			const timer = setTimeout(() => {
				setVisible(false);
			}, HIDE_TOAST_IN_SECONDS * 1000);

			return () => clearTimeout(timer);
		}
	}, [toast]);

	return (
		<ToastContext.Provider value={{ setToast }}>
			{children}
			{
				toast &&
				<Toast
					title={toast.title}
					message={toast.message}
					type={toast.type}
					visible={visible}
					setVisible={setVisible} />
			}
		</ToastContext.Provider>
	);
};

export default ToastProvider;
export { ToastContext };
