import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faCameraRetro, faComputer, faGamepad, faImage, faServer } from "@fortawesome/free-solid-svg-icons";
import "./style.scss";

const Loading = ({ type = "" }) => {
	if (type === "email") return (
		<div className="loading row align-center">
			<div className="pc-icon">
				<FontAwesomeIcon className="icon" icon={faComputer} />
			</div>
			<div className="mail-icon">
				<FontAwesomeIcon className="icon" icon={faEnvelope} />
			</div>
			<div className="server-icon">
				<FontAwesomeIcon className="icon" icon={faServer} />
			</div>
		</div>
	);

	return (
		<div className="loading row align-center">
			<div className="gamepad-icon">
				<FontAwesomeIcon className="icon" icon={faGamepad} />
			</div>

			<div className="dots">
				<div className="dot"></div>
				<div className="dot"></div>
				<div className="dot"></div>
			</div>

			<div className="camera-icon">
				<FontAwesomeIcon className="icon" icon={faCameraRetro} />
			</div>

			<div className="dots">
				<div className="dot"></div>
				<div className="dot"></div>
				<div className="dot"></div>
			</div>

			<div className="images-icon">
				<div className="icons-content">
					<FontAwesomeIcon className="icon" icon={faImage} />
					<FontAwesomeIcon className="icon" icon={faImage} />
				</div>
			</div>
		</div>
	);
};

export default Loading;