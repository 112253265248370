import { Routes, Route } from "react-router-dom";
import "App.scss";

import Portfolio from "pages/Portfolio";
import Editor from "pages/Editor";
//import Demo from "pages/Demo";


const App = () => {
	return (
		<div className="app no-scrollbar">
			<Routes>
				<Route path="/*" element={<Portfolio />} />
				<Route path="/editor/*" element={<Editor />} />
				{/*<Route path="/demo/*" element={<Demo />} />*/}
			</Routes>
		</div>
	);
};

export default App;
