import { useState } from "react";
import parse from "class-parser";
import "./style.scss";

const CheckInput = ({ label, checked, setChecked }) => {
	const handleCheck = event => {
		setChecked(event.target.checked);
	};

	return (
		<label className={parse("input check", { checked })}>
			{label && <span>{label}</span>}
			<div className="toggle">
				<div className="track">
					<div className="circle"></div>
				</div>
			</div>
			<input
				type="checkbox"
				checked={checked}
				onChange={handleCheck} />
		</label>
	);
};

const TextInput = ({ value, setValue, label, placeHolder, textArea, isPassword, focused, error }) => {
	const [focus, setFocus] = useState(focused);

	const handleValue = event => {
		setValue(event.target.value);
	};

	return (
		<div className={parse("input text", { focus, error })}>
			{label && <p>{label}</p>}

			{!textArea ? (
				<input
					type={isPassword ? "password" : "text"}
					value={value}
					placeholder={placeHolder}
					onChange={handleValue}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)} />
			) : (
				<textarea
					value={value}
					placeholder={placeHolder}

					onChange={handleValue}
					onFocus={() => setFocus(true)}
					onBlur={() => setFocus(false)} />
			)}

			<div className="line">
				<div className="fill"></div>
			</div>
		</div>
	);
};

export { CheckInput, TextInput };
