import { useState } from "react";

const url = "https://us-central1-rimaeternax-3cf9d.cloudfunctions.net/sendMail";

export const sendEmail = () => {
	const [success, setSuccess] = useState(null);
	const [sending, setSending] = useState(false);

	const send = data => {
		const config = {
			method: "POST",
			headers: {
				"Accept": "application/json",
				"Content-Type": "application/json",
			},
			body: JSON.stringify(data),
		};

		setSuccess(null);
		setSending(true);

		fetch(url, config).then(res => {
			setSuccess(res.ok);
			setSending(false);
		}).catch(error => {
			setSuccess(false);
			setSending(false);
			console.log(error);
		});
	};

	return { send, success, sending };
};