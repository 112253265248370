import { useEffect } from "react";
import parse from "class-parser";
import "./style.scss";

const Button = ({ label, onClick, filled, type, active }) => {
	const handleClick = () => {
		if (active && onClick) onClick();
	};

	return (
		<div
			className={parse("button", { active, type })}
			onClick={handleClick}
		>
			{label}
		</div>
	);
};

export default Button;
