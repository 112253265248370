import * as Firestore from "firebase/firestore";
import { database } from "services/firebase";

const GALLERIES_COLLECTION = "galleries";
const GALLERIES_IMAGES = "images";
const DATA_COLLECTION = "data";
const DATA_GALLERIES_DOCUMENT = "galleries";

// Galleries storage reference
const galleriesCollection = Firestore.collection(database, GALLERIES_COLLECTION);
const galleriesDataRef = Firestore.doc(database, DATA_COLLECTION, DATA_GALLERIES_DOCUMENT);

//#region PORTFOLIO METHODS
export const getCovers = async () => {
	const covers = [];

	try {
		const coversQuery = Firestore.query(galleriesCollection, Firestore.where("visible", "==", true));
		const docs = await Firestore.getDocs(coversQuery);

		docs.forEach(doc => {
			const { label, url, cover } = doc.data();
			covers.push({
				id: doc.id,
				cover,
				label,
				url,
			});
		});

	}
	catch (error) {
		console.log(error);
	}

	return covers;
};
export const getGalleryFromURL = async (url) => {
	const data = {};

	try {
		const galleryQuery = Firestore.query(galleriesCollection, Firestore.where("url", "==", url), Firestore.limit(1));
		const docs = await Firestore.getDocs(galleryQuery);

		let id;
		docs.forEach(doc => {
			id = doc.id;
			data.order = doc.data().imagesOrder;
			data.title = doc.data().label;
			data.images = [];
		});

		const galleryRef = Firestore.doc(galleriesCollection, id);
		const imagesCollection = Firestore.collection(galleryRef, "images");
		const imagesQuery = Firestore.query(imagesCollection);
		const images = await Firestore.getDocs(imagesQuery);

		images.forEach(image => {
			data.images.push({
				id: image.id,
				url: image.data().fullSize,
				landscape: image.data().landscape,
			});
		});
	}
	catch (error) {
		console.log(error);
	}

	return data;
};
//#endregion

//#region GALLERIES METHODS
export const addNewGallery = async ({ label, url }, currentOrder) => {
	try {
		const newGallery = {
			label,
			url,
			imagesOrder: [],
			cover: "",
			visible: true,
		};
		const docReference = await Firestore.addDoc(galleriesCollection, newGallery);

		return Firestore.updateDoc(galleriesDataRef, { order: [docReference.id, ...currentOrder] }, { merge: true });
	}
	catch (error) {
		console.log(error);
	}
};
export const changeCover = (id, url) => {
	const galleryRef = Firestore.doc(database, GALLERIES_COLLECTION, id);
	return Firestore.setDoc(galleryRef, { cover: url }, { merge: true });
};
export const deleteGallery = (id, orderGalleries, orderImages) => {
	try {
		const galleryRef = Firestore.doc(database, GALLERIES_COLLECTION, id);

		orderImages.forEach(async imageId => {
			const imageRef = Firestore.doc(galleryRef, GALLERIES_IMAGES, imageId);
			const doc = await Firestore.getDoc(imageRef);

			if (doc.exists()) Firestore.deleteDoc(doc);
		});

		Firestore.deleteDoc(galleryRef).then(() => {
			const indexId = orderGalleries.indexOf(id);
			const newOrder = [...orderGalleries];

			newOrder.splice(indexId, 1);
			//console.log(newOrder);

			return updateGalleriesOrder(newOrder);
		});
	}
	catch (error) {
		console.log(error);
	}
};
export const getAllGalleries = async () => {
	const galleriesQuery = Firestore.query(galleriesCollection);
	const docs = await Firestore.getDocs(galleriesQuery);

	const galleries = [];
	docs.forEach(doc => {
		const gallery = doc.data();
		gallery.id = doc.id;
		galleries.push(gallery);
	});

	return galleries;
};
export const getGalleriesImages = async (id) => {
	const galleryRef = Firestore.doc(database, GALLERIES_COLLECTION, id);
	const imagesCollection = Firestore.collection(galleryRef, "images");
	const imagesQuery = Firestore.query(imagesCollection);
	const docs = await Firestore.getDocs(imagesQuery);

	const images = [];
	docs.forEach(doc => {
		const image = doc.data();
		image.id = doc.id;
		images.push(image);
	});

	return images;
};
export const getGalleriesOrder = async () => {
	try {
		const doc = await Firestore.getDoc(galleriesDataRef);

		return doc.exists() ? doc.data() : { message: "No galleries found" };
	}
	catch (error) { return { message: error.message }; }
};
export const updateGalleriesOrder = async (order) => {
	try {
		return await Firestore.setDoc(galleriesDataRef, { order });
	}
	catch (error) {
		console.log(error);
	}
};
export const updateGallery = (id, data) => {
	try {
		const galleryRef = Firestore.doc(database, GALLERIES_COLLECTION, id);
		return Firestore.setDoc(galleryRef, data, { merge: true });
	}
	catch (error) {
		console.log(error);
	}
};
//#endregion

//#region IMAGES METHODS
export const addImagesOnGallery = async (galleryId, data) => {
	const galleryRef = Firestore.doc(database, GALLERIES_COLLECTION, galleryId);
	const imagesCollection = Firestore.collection(galleryRef, GALLERIES_IMAGES);
	const doc = await Firestore.addDoc(imagesCollection, data);

	return doc.id;
};
export const addImagesOrder = async (id, order) => {
	const galleryRef = Firestore.doc(database, GALLERIES_COLLECTION, id);
	const currentOrder = await getImagesOrder(id);

	const newOrder = [...currentOrder, ...order];

	Firestore.updateDoc(galleryRef, { "imagesOrder": newOrder });
};
export const deleteImageOnGallery = async (galleryId, imageId) => {
	const galleryRef = Firestore.doc(database, GALLERIES_COLLECTION, galleryId);
	const imagesRef = Firestore.doc(galleryRef, GALLERIES_IMAGES, imageId);
	const doc = await Firestore.getDoc(imagesRef);

	if (!doc.exists()) return;

	Firestore.deleteDoc(imagesRef);
	Firestore.updateDoc(galleryRef, {
		"imagesOrder": Firestore.arrayRemove(imageId),
	});

	return doc.data().name;
};
export const getImagesOrder = async (id) => {
	const galleryRef = Firestore.doc(database, GALLERIES_COLLECTION, id);
	const doc = await Firestore.getDoc(galleryRef, "imagesOrder");

	return doc.exists() ? doc.data().imagesOrder : [];
};
export const updateImagesOrder = (id, order) => {
	const galleryRef = Firestore.doc(database, GALLERIES_COLLECTION, id);

	return Firestore.updateDoc(galleryRef, { "imagesOrder": order });
};
//#endregion

//#region ABOUT-ME METHODS
export const getAboutMeInfo = async () => {
	const aboutRef = Firestore.doc(database, "admin", "about");
	const doc = await Firestore.getDoc(aboutRef);

	return doc.exists() ? doc.data() : { message: "Buscar esta informaci'on en la cache!!!" };
};
//#endregion
