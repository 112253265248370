import parse from "class-parser";
import "./style.scss";

const Toast = ({ title, message, type, visible, setVisible }) => {
	const handleDismiss = () => {
		setVisible(false);
	};

	return (
		<div className={parse("toast", type, {visible})}>
			<div className="decorator"></div>

			<div className="content">
				<p>
					<span className="title">
						{title}
					</span>
					{message}
				</p>
			</div>
			
			<div className="options">
				<div
					className="button"
					onClick={handleDismiss}
				>Dismiss</div>
			</div>
		</div>
	);
};

export default Toast;
