import React, { Fragment, useState, useEffect, useContext } from "react";
import parse from "class-parser";
import "./style.scss";

import * as Database from "services/firebase/database";
import * as Storage from "services/firebase/storage";
import { arrayMoveElement } from "services/utils";

import { ToastContext } from "providers/toast";

import Button from "components/Button";
import LoadImagesPopup from "components/LoadImagesPopup";
import { CheckInput, TextInput } from "components/Input";

/**
 * TO-DO:
 * Añadir una foto nueva o seleccionar una existente 
 * de la galería y editarla para ponerla como portada.
 * 
 * Guardar localmente (cache, cookies, etc.) y/o 
 * en el servidor los cambios no guardados.
 */

const Gallery = ({ id, label, url, visible, cover, selected, onSelect, onDrag, onDrop, refreshGalleries, setLoadImages }) => {
	const { setToast } = useContext(ToastContext);

	const [newData, setNewData] = useState({ label, url, visible });
	const [changes, setChanges] = useState(false);
	const [dragOver, setDragOver] = useState(false);

	//#region HANDLERS
	const handleDragEnter = event => {
		event.preventDefault();
	};
	const handleDragExit = event => {
		event.preventDefault();
		setDragOver(false);
	};
	const handleDragOver = event => {
		event.preventDefault();
		setDragOver(true);
	};
	const handleDrop = () => {
		onDrop();
		setDragOver(false);
	};

	const handleChangeData = (value, target) => {
		setNewData(prev => {
			const data = { ...prev };
			data[target] = value;

			return data;
		});
	};
	const handleSaveChanges = () => {
		Database.updateGallery(id, newData)
			.then(() => {
				setChanges(false);
				refreshGalleries();
				setToast({
					title: "Changes saved!",
					message: `Yay! Changes on ${newData.label} has been saved sucessfully!`,
					type: "success"
				});
			})
			.catch(error => {
				setToast({
					title: "Wops!",
					message: "Something went wrong saving the changes...",
					type: "error"
				});

				console.log(error);
			});
	};
	const handleDiscardChanges = () => {
		setNewData({ label, url, visible });
	};
	//#endregion

	//#region EFFECTS
	useEffect(() => {
		if (changes) {
			const formattedUrl = newData.label
				.toLowerCase()
				.replace(/ /g, "-")
				.replace(/iii/g, "3")
				.replace(/ii/g, "2");
			handleChangeData(formattedUrl, "url");
		}
	}, [newData.label]);

	useEffect(() => {
		setChanges(
			newData.label !== label ||
			newData.url !== url ||
			newData.visible !== visible
		);
	}, [newData]);
	//#endregion

	return (
		<div
			className={parse("gallery", { selected, dragOver })}
			draggable={!selected}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragExit}
			onDragOver={handleDragOver}
			onDragStart={onDrag}
			onDrop={handleDrop}
		>
			<div
				className="handle"
				onClick={onSelect}
			>
				<div className="label">
					<div className={parse("bullet", { changes })}></div>
					{label}
				</div>
			</div>
			{
				selected &&
				<div className="properties">
					<TextInput
						value={newData.label}
						setValue={value => handleChangeData(value, "label")}
						label="Title" />

					<TextInput
						value={newData.url}
						setValue={value => handleChangeData(value, "url")}
						label="URL" />

					<div className="row">
						<div className="cover">
							{cover && <img src={cover} />}
						</div>
						<div className="options expand">
							<CheckInput
								label="Visible in portfolio"
								checked={newData.visible}
								setChecked={value => handleChangeData(value, "visible")} />

							<div className="expand align-center">
								<Button
									label={"Add Images to Gallery"}
									onClick={() => setLoadImages(true)}
									active />
							</div>

							<div className="changes-buttons row expand align-center">
								<Button
									label="Save"
									//type="success"
									onClick={handleSaveChanges}
									active={changes} />

								<Button
									label="Cancel"
									type="error"
									onClick={handleDiscardChanges}
									active={changes} />
							</div>
						</div>
					</div>
				</div>
			}
		</div>
	);
};

const Image = ({ data, setSelectedImageId, selected, onDrag, onDrop }) => {
	const className = parse("image", {
		landscape: data.landscape,
		selected: selected,
	});
	const imgStyle = {
		backgroundImage: `url(${data.thumb})`,
	};

	//#region HANDLERS
	const handleClick = () => {
		setSelectedImageId(data.id);
	};
	const handleDragEnter = event => {
		event.preventDefault();
	};
	const handleDragExit = event => {
		event.preventDefault();
		//setDragOver(false);
	};
	const handleDragOver = event => {
		event.preventDefault();
		//setDragOver(true);
	};
	const handleDrop = () => {
		onDrop();
		//setDragOver(false);
	};
	//#endregion

	return (
		<div
			className={className}
			style={imgStyle}
			draggable
			onClick={handleClick}
			onDragEnter={handleDragEnter}
			onDragLeave={handleDragExit}
			onDragOver={handleDragOver}
			onDragStart={onDrag}
			onDrop={handleDrop}
		/>
	);
};

const CurrentGallery = ({ id, title, refreshGalleries, loadImages }) => {
	const [images, setImages] = useState([]);
	const [order, setOrder] = useState([]);
	const [selectedImageId, setSelectedImageId] = useState(null);
	const [orderChanged, setOrderChanged] = useState(false);

	let dragImageOrder = null;

	//#region METHODS
	const getImages = id => {
		Promise.all([
			Database.getGalleriesImages(id),
			Database.getImagesOrder(id),
		]).then(([imgData, orderData]) => {
			setImages(imgData);
			setOrder(orderData);
		});
	};
	//#endregion

	//#region HANDLERS
	const handleChangeCover = () => {
		const coverImage = images.find(image => image.id === selectedImageId);
		//Database.changeCover(id, coverImage.mobile).then(() => {
		Database.changeCover(id, coverImage.fullSize).then(() => {
			refreshGalleries();
		});
	};

	const handleOnDrag = id => {
		dragImageOrder = id;
	};

	const handleOnDrop = target => {
		const newOrder = arrayMoveElement(order, dragImageOrder, target);
		dragImageOrder = null;
		setOrder(newOrder);
		setOrderChanged(true);
	};

	const handleDeleteImage = () => {
		Database.deleteImageOnGallery(id, selectedImageId).then(name => {
			Storage.deleteImage(id, name).then(() => {
				setSelectedImageId(null);
				getImages(id);
			});
		});
	};

	/*const handleDeleteGallery = () => {
		
	};*/

	const handleSaveImagesOrder = () => {
		Database.updateImagesOrder(id, order).then(() => {
			setOrderChanged(false);
		});
	};
	//#endregion

	//#region EFFECTS
	useEffect(() => {
		if (id && !loadImages) getImages(id);
	}, [id, loadImages]);
	//#endregion

	return (
		<div className="current-gallery row expand">
			<div className="images expand">
				{order?.map((id, index) => {
					const image = images.find(img => img.id === id);
					if (image) {
						return (
							<Image
								key={image.id}
								data={image}
								selected={image.id === selectedImageId}
								setSelectedImageId={setSelectedImageId}
								onDrag={() => handleOnDrag(index)}
								onDrop={() => handleOnDrop(index)} />
						);
					}
				})}
			</div>
			<div className="settings">
				<div className="settings-content expand">
					<div className="save-order">
						<Button
							label="Save order"
							active={orderChanged}
							onClick={handleSaveImagesOrder} />
					</div>
					{selectedImageId && <Fragment>
						<div className="image-type">
							<h3>Set size</h3>
							<div className="row">
								<div className="box-size" />
								<div className="box-size" />
								<div className="box-size" />
							</div>
							<div className="row">
								<div className="box-size" />
								<div className="box-size" />
								<div className="box-size" />
							</div>
							<div className="row">
								<div className="box-size" />
								<div className="box-size" />
								<div className="box-size" />
							</div>
						</div>
						<div className="set-thumb">
							<Button
								label="Set as thumb image"
								active={selectedImageId !== null}
								onClick={handleChangeCover} />
						</div>
						<div className="delete-image">
							<Button
								label="Delete selected image"
								type="error"
								active={selectedImageId !== null}
								onClick={handleDeleteImage} />
						</div>
					</Fragment>}
				</div>
				<div className="settings-footer align-center">
					<Button
						label={`Delete ${title} gallery`}
						type="error"
						/*onClick={handleDeleteGallery}*/ />
				</div>
			</div>
		</div>
	);
};

const GalleriesEditor = () => {
	const { setToast } = useContext(ToastContext);

	const [galleries, setGalleries] = useState([]);
	const [orderGalleries, setOrderGalleries] = useState([]);
	const [currentGalleryId, setCurrentGalleryId] = useState(null);
	const [orderChanged, setOrderChanged] = useState(false);
	const [loadImages, setLoadImages] = useState(false);
	const [message, setMessage] = useState(null);

	let dragGalleryOrder = null;

	//#region METHODS
	const refreshGalleries = () => {
		Database.getGalleriesOrder().then((data) => {
			if (data.message) {
				setMessage(data.message);
				return;
			}

			Database.getAllGalleries().then(data => {
				setGalleries([...data]);
			});

			setOrderGalleries(() => {
				setOrderChanged(false);
				return [...data.order];
			});
		});
	};
	//#endregion

	//#region HANDLERS
	const handleAddNewGallery = () => {
		const newGallery = {
			label: "New Gallery",
			url: "new-gallery",
		};
		Database.addNewGallery(newGallery, orderGalleries)
			.then(() => {
				refreshGalleries();
			});
	};

	const handleSaveGalleriesOrder = () => {
		Database.updateGalleriesOrder(orderGalleries)
			.then(() => {
				refreshGalleries();

				setToast({
					title: "Saving the new order",
					message: "This can take a while...",
					type: "warn"
				});
			});
	};

	const handleSelectGallery = id => {
		setCurrentGalleryId(id === currentGalleryId ? null : id);
	};

	const handleDragGallery = index => {
		dragGalleryOrder = index;
	};

	const handleDropGallery = target => {
		const newOrder = arrayMoveElement(orderGalleries, dragGalleryOrder, target);
		dragGalleryOrder = null;
		setOrderGalleries(newOrder);
		setOrderChanged(true);
	};
	//#endregion

	//#region EFFECTS
	useEffect(() => {
		refreshGalleries();
	}, []);
	//#endregion

	return message ? message : (
		<div className="galleries-editor row">
			<div className="galleries">
				<div className="galleries-content expand">
					{orderGalleries.map((order, index) => {
						const gallery = galleries.find(gallery => order === gallery.id);

						if (gallery) {
							const { id, label, url, cover, visible } = gallery;

							return (
								<Gallery
									key={id}
									id={id}
									label={label}
									url={url}
									cover={cover}
									visible={visible}
									selected={id === currentGalleryId}
									onSelect={() => handleSelectGallery(id)}
									onDrag={() => handleDragGallery(index)}
									onDrop={() => handleDropGallery(index)}
									refreshGalleries={refreshGalleries}
									setLoadImages={setLoadImages} />
							);
						}
					})}
				</div>
				<div className="galleries-footer row">
					<Button
						label="+ Add new gallery"
						onClick={handleAddNewGallery}
						active />

					<Button
						label="Save Order"
						onClick={handleSaveGalleriesOrder}
						active={orderChanged} />
				</div>
			</div>

			{currentGalleryId &&
				<CurrentGallery
					id={currentGalleryId}
					title={galleries.find(gallery => gallery.id === currentGalleryId)?.label}
					refreshGalleries={refreshGalleries}
					loadImages={loadImages} />
			}

			{loadImages &&
				<LoadImagesPopup
					galleryId={currentGalleryId}
					setLoadImages={setLoadImages} />
			}
		</div>
	);
};

export default GalleriesEditor;
