import { Link } from "react-router-dom";
import "./style.scss";

const Cover = ({ label, cover, url }) => {
	return (
		<Link className="cover" to={"/" + url}>
			<div
				className="image"
				style={{
					backgroundImage: `url(${cover})`
				}} />
			<div className="label">{label}</div>
		</Link>
	);
};

export default Cover;
