import { lazy, useState, useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import * as Database from "services/firebase/database";
import "./style.scss";

import Header from "components/Header";
import Footer from "components/Footer";

import Covers from "./sections/Covers";
import Gallery from "./sections/Gallery";
import About from "./sections/About";
import Contact from "./sections/Contact";
import Loading from "components/Loading";

/**
 * TO-DO:
 * 
 * Terminar la opción para cambiar el tamaño
 * de las imágenes en el editor.
 * 
 * Habilitar la opción de borrar las galerias.
 */

const Portfolio = () => {
	const [covers, setCovers] = useState([]);
	const [info, setInfo] = useState(null);
	const [message, setMessage] = useState("");

	const [navOpen, setNavOpen] = useState(false);

	//#region HANDLERS
	const handleSetNav = (state = null) => {
		setNavOpen(prev => {
			return (state === null ? !prev : state);
		});
	};
	const handleCloseNav = () => {
		if (navOpen) setNavOpen(false);
	};
	//#endregion

	//#region EFFECTS
	useEffect(() => {
		Database.getGalleriesOrder().then((data) => {
			if (data.message) {
				setMessage(data.message);
				return;
			}

			Database.getCovers().then(galleries => {
				const sortedGalleries = [];
				data.order.forEach(id => {
					const gallery = galleries.find(gallery => gallery.id === id);

					if (gallery) sortedGalleries.push(gallery);
				});

				setCovers(sortedGalleries);
			});
		});

		Database.getAboutMeInfo().then(data => {
			const newData = {};
			const age = new Date(new Date() - new Date("09/04/1992")).getFullYear() - 1970;

			newData.title = data["en"].title;
			newData.description = data["en"].description.replace("[age]", age);
			newData.profilePic = data["profile_pic"];

			setInfo(newData);
		});
	}, []);
	//#endregion

	return (
		<div className="portfolio expand">
			<Header navOpen={navOpen} handleSetNav={handleSetNav} />

			{covers.length === 0 ? <Loading /> : (
				<div
					className="portfolio-content expand align-horizontal-center"
					onClick={handleCloseNav}
				>
					<Routes>
						<Route index element={<Covers data={covers} message={message} />} />
						<Route path="about-me" element={<About data={info} />} />
						<Route path="contact-me" element={<Contact />} />
						<Route path="/:id" element={<Gallery />} />
					</Routes>
				</div>
			)}

			<Footer />
		</div>
	);
};

export default Portfolio;
