// Arrays
export const arrayRange = (min, max, include = false) => {
	const range = [];
	for (let i = min; i < max + (include ? 1 : 0); i++) range.push(i);
	return range;
};

export const arrayMoveElement = (arr, from, to) => {
	const order = [...arr];
	const el = arr[from];
	order.splice(from, 1);
	order.splice(to, 0, el);
	return order;
};

// String
export const replaceAll = (target) => {

};
