import React, { useState, useEffect } from "react";
import "./style.scss";

import * as Storage from "services/firebase/storage";

import Button from "components/Button";
import * as Firestore from "services/firebase/database";

const Thumb = ({ image, handleDelete }) => {
	//const [loaded, setLoaded] = useState(false);

	//#region METHODS\
	//#endregion

	//#region EFFECTS
	//#endregion

	return (
		<li
			className="thumb"
			key={image.id}
		>
			<div
				className="image"
				style={{ backgroundImage: `url(${image.thumb})` }} />

			<div className="options expand">
				<div className="delete">
					<Button
						label="X"
						type="error"
						onClick={() => handleDelete(image.id)}
						active />
				</div>
				<div className="feedback">

				</div>
			</div>
		</li>
	);
};

const Thumbs = ({ images, setImages }) => {
	//#region HANDLERS
	const handleDelete = id => {
		const target = images.find(image => image.id === id);
		const index = images.indexOf(target);
		const newImages = [...images];

		newImages.splice(index, 1);

		setImages(newImages);
	};
	//#endregion

	return (
		<ul className="thumbs">
			{images.map(image => (
				<Thumb
					key={image.id}
					image={image}
					handleDelete={handleDelete} />
			))}
		</ul>
	);
};

const LoadImagesPopup = ({ galleryId, setLoadImages }) => {
	const [images, setImages] = useState([]);

	//#region METHODS
	const createImageSizes = image => {
		//const sizes = [2000, 640, 350];
		const sizes = [2000, 350];

		const canvas = document.createElement("canvas");
		const context = canvas.getContext("2d");

		let landscape = false;

		const data = [];
		sizes.forEach(size => {
			let width, height;

			if (image.width > image.height) {
				// Landscape image
				const ratio = size / image.width;

				width = size;
				height = image.height * ratio;

				landscape = true;
			}
			else {
				// Portrait image
				const ratio = size / image.height;

				width = image.width * ratio;
				height = size;
			}

			canvas.width = width;
			canvas.height = height;

			context.drawImage(image, 0, 0, width, height);

			data.push(canvas.toDataURL("image/jpeg", 0.75));
		});

		return { sizes: data, landscape };
	};

	const preventDefaults = event => {
		event.preventDefault();
		event.stopPropagation();
	};
	//#endregion

	//#region HANDLERS
	const handleFiles = (event, files) => {
		preventDefaults(event);

		[...files].forEach(file => {
			if (!file.type.includes("image/")) return;

			const image = new Image();
			image.onload = () => {
				const { sizes, landscape } = createImageSizes(image);
				const imageData = {
					id: Date.now(),
					fullSize: sizes[0],
					thumb: sizes[1],
					//mobile: sizes[1],
					//thumb: sizes[2],
					landscape,
				};

				setImages(prev => [...prev, imageData]);

				URL.revokeObjectURL(image.src);
			};
			image.src = URL.createObjectURL(file);
		});

		files = null;
	};

	const handleDropImages = event => {
		const files = event.dataTransfer.files;
		handleFiles(event, files);
	};

	const handleUploadImages = () => {
		Promise.all(images.map(image =>
			Promise.all([
				Storage.uploadImage(image.fullSize, galleryId, image.id + "_fullSize"),
				Storage.uploadImage(image.thumb, galleryId, image.id + "_thumb"),
			]).then(result => {
				return {
					id: image.id,
					urls: result,
					landscape: image.landscape
				};
			})
		)).then(result => {
			Promise.all(result.map(image => {
				const imageData = {
					name: image.id,
					landscape: image.landscape,
					fullSize: image.urls[0],
					thumb: image.urls[1],
					//mobile: image.urls[1],
					//thumb: image.urls[2],
				};
				return Firestore.addImagesOnGallery(galleryId, imageData);
			})).then((result) => {
				Firestore.addImagesOrder(galleryId, result);
				handleCancel();
			});
		});
	};

	const handleCancel = () => {
		setImages([]);
		setLoadImages(false);
	};
	//#endregion

	//#region EFFECTS
	/*useEffect(() => {
		
	}, []);*/
	//#endregion

	return (
		<div className="load-images align-center">
			<div className="load-images-popup">
				<div className="load-images-popup-content expand">
					<h2>Select or drop all the images to upload</h2>
					<input
						className="load-btn"
						type="file" multiple
						onChange={event => handleFiles(event, event.target.files)} />

					<div
						className="drop-container expand"
						onDragEnter={preventDefaults}
						onDragOver={preventDefaults}
						onDragLeave={preventDefaults}
						onDrop={event => handleDropImages(event)}
					>
						<Thumbs
							images={images}
							setImages={setImages} />
					</div>
					<div className="buttons row">
						<Button
							label="Cancel"
							type="error"
							active={true /*loading === images.length*/}
							onClick={handleCancel} />

						<Button
							label="Upload Images"
							filled
							active={images.length > 0 /*loading > 0 && loading === images.length*/}
							onClick={handleUploadImages} />
					</div>
				</div>
			</div>
		</div>
	);
};

export default React.memo(LoadImagesPopup);
