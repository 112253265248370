import { useContext, useState } from "react";
import { loginUsernameAndPassw } from "services/firebase/auth";

import { ToastContext } from "providers/toast";
import "./style.scss";

import { TextInput } from "components/Input";
import Button from "components/Button";

const Login = () => {
	const { setToast } = useContext(ToastContext);

	const [username, setUsername] = useState("");
	const [passw, setPassw] = useState("");

	const handleLogin = () => {
		loginUsernameAndPassw(username, passw, () => {
			setToast({
				title: "Yay!",
				message: "You logged in successfully!",
				type: "success"
			});
		});
		setUsername("");
		setPassw("");
	};

	return (
		<div className="login">
			<div className="form">
				<div className="content">
					<h3>Login</h3>

					<TextInput
						label="Username"
						value={username}
						setValue={setUsername} />

					<TextInput
						label="Password"
						value={passw}
						setValue={setPassw}
						isPassword />

					<Button
						label="Log in"
						onClick={handleLogin}
						active={username !== "" && passw !== ""} />
				</div>
			</div>
		</div>
	);
};

export default Login;
