import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

//#region INIT
const config = {
	apiKey: "AIzaSyBC3OcaMYpPvrrmKbdgMPMxWmMTZbktf6k",
	authDomain: "rimaeternax-3cf9d.firebaseapp.com",
	databaseURL: "https://rimaeternax-3cf9d-default-rtdb.europe-west1.firebasedatabase.app",
	projectId: "rimaeternax-3cf9d",
	storageBucket: "rimaeternax-3cf9d.appspot.com",
	messagingSenderId: "985012849272",
	appId: "1:985012849272:web:efe4dc77180e312d8c7b8d",
	measurementId: "G-457KQHDTGY"
};
const app = initializeApp(config);
//#endregion

export const auth = getAuth(app);
export const database = getFirestore(app);
export const storage = getStorage(app);
